import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const ExeKGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page exilium-page'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_guides.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Girls' Frontline 2: Exilium Guides</h1>
          <h2>
            Best guides for Girls' Frontline 2: Exilium that will help you
            understand the game better
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>
            Our Girls' Frontline 2: Exilium Database is currently being created!
          </h4>
          <p>
            Over the next few weeks expect the database to be updated daily with
            new content.
          </p>
        </div>
      </div>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/gfl-exilium/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
      </Row>
    </DashboardLayout>
  );
};

export default ExeKGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Best guides for Girls' Frontline 2: Exilium that will help you understand the game better."
    game="exilium"
  />
);
